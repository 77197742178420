.cursor-pointer {
  cursor: pointer;
}

.form-control-position {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 2;
  display: block;
  width: 2rem;
  height: 2.5rem;
  line-height: 2.2rem;
  text-align: center;
}

.postImages {
  height: 3rem;
  width: 3rem;
}

.userImage {
  height: 4rem;
  width: 4rem;
}

.profileImage {
  height: 10rem;
  width: 10rem;
}

.login-width {
  width: 25rem;
  max-width: 90%;
}
/* loader */
.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  left: -100px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 var(--asm-primary), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 var(--asm-primary), 100px 0 var(--asm-primary),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 var(--asm-primary), 110px 0 var(--asm-primary),
      100px 0 var(--asm-primary), 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 var(--asm-primary), 120px 0 var(--asm-primary),
      110px 0 var(--asm-primary), 100px 0 var(--asm-primary);
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--asm-primary),
      120px 0 var(--asm-primary), 110px 0 var(--asm-primary);
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 var(--asm-primary), 120px 0 var(--asm-primary);
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 130px 0 var(--asm-primary);
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

/* endloader */

/* sidebar */
.backdrop {
  position: fixed;
  inset: 0;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1099;
}
.backdrop.show {
  opacity: 1;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar {
  position: fixed;
  background-color: #fff;
  width: 30rem;
  max-width: 100%;
  position: fixed;
  z-index: 1100;
  top: 0;
  bottom: 0;
  left: -100%;
  overflow-x: hidden;
  padding-top: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.sidebar.show {
  left: 0;
}
.sidebar .top {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.6rem;
  cursor: pointer;
}

.navbar-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin: 0 1rem 0 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-wrap: nowrap;
}

.text-size-sm {
  font-size: 0.8rem;
}

.text-size-lg {
  font-size: 1.2rem;
}

.text-size-xl {
  font-size: 1.5rem;
}

.collapse {
  transition: height 0.35s ease;
}

.asm-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1201;
  scale: 0.1;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  width: 30rem;
  max-width: 100%;
  max-height: 80vh;
}

.asm-modal.active {
  scale: 1;
  opacity: 1;
  pointer-events: all;
}

.asm-modal .content {
  max-height: 65vh;
  overflow-y: scroll;
}

.asm-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.asm-backdrop.active {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.chart-height {
  height: 80vh !important;
}

.adminSidebar {
  position: fixed;
  background-color: #fff;
  width: 50rem;
  max-width: 100%;
  z-index: 1100;
  top: 0;
  bottom: 0;
  right: -100%;
  overflow-x: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  background-color: #f4f5fa;
}

.adminSidebar.show {
  right: 0;
}

.postText {
  max-height: 6.3rem;
  overflow-y: scroll;
}

.messages {
  position: absolute;
  inset: 0;
  overflow-y: scroll;
  margin: 4rem 0 4.3rem 0;
}

.messages .message {
  width: 90%;
}

.send {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.dropdown-width {
  width: 90%;
}
