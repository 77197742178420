:root {
  height: 100%;
  --asm-primary: #891f50;
  --asm-secondary: #980047;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #f4f5fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
}

.asm-color-primary {
  color: var(--asm-primary) !important;
}

.asm-bg-color-primary {
  background-color: var(--asm-primary) !important;
}

.asm-btn-bg-color-primary {
  background-color: var(--asm-primary) !important;
  transition: all 0.3s ease-in-out;
}

.asm-btn-bg-color-primary:hover {
  background-color: var(--asm-secondary) !important;
}

/* .asm-bg-color-primary:hover {
  background-color: var(--asm-secondary) !important;
} */

.asm-btn-color-primary {
  color: var(--asm-primary) !important;
  transition: all 0.3s ease-in-out;
}
.asm-btn-color-primary:hover {
  color: white !important;
  background-color: var(--asm-primary) !important;
}

.asm-bg-faded-primary {
  background-color: var(--asm-primary) !important;
  color: white !important;
}
